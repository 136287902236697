import { FormControlLabel } from '@mui/material';
import {
  ChangeEvent, FocusEvent, ReactElement, useEffect,
} from 'react';
import classNames from 'classnames';

import styles from './CheckboxItem.module.scss';
import Checkbox from './Checkbox';

const preloadSVGs = (svgNames: string[]) => {
  svgNames.forEach((name: string) => {
    const img = new Image();
    img.src = `/media/icons/${name}.svg#${name}SVG`;
  });
};

export enum CheckboxLabelPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  START = 'start',
  END = 'end',
}

type CheckboxItemProps = {
  size?: number,
  value: boolean,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (e: FocusEvent<HTMLButtonElement>) => void,
  label?: string | ReactElement,
  name?: string,
  labelPlacement?: CheckboxLabelPlacement,
  labelClassName?: string,
  indeterminate?: boolean,
  disabled?: boolean,
};
const CheckboxItem = ({
  value,
  onChange,
  onBlur,
  label,
  size = 18,
  name,
  labelPlacement = CheckboxLabelPlacement.END,
  labelClassName,
  indeterminate,
  disabled = false,
}: CheckboxItemProps) => {
  useEffect(() => {
    preloadSVGs(['checkboxEmpty', 'checkboxIndeterminate', 'checkboxFilled']);
  }, []);

  return (
    <FormControlLabel
      className={classNames(styles.checkboxLabel, labelClassName)}
      label={label}
      labelPlacement={labelPlacement}
      disabled={disabled}
      sx={{
        gap: '8px',
        margin: '0',
      }}
      control={(
        <Checkbox
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          indeterminate={indeterminate}
          disabled={disabled}
          size={size}
        />
      )}
    />
  );
};

export default CheckboxItem;
