import { Checkbox as MuiCheckbox } from '@mui/material';
import {
  ChangeEvent, FocusEvent,
} from 'react';

import styles from './CheckboxItem.module.scss';
import Svg from '../Svg';

type CheckboxProps = {
  size?: number,
  value: boolean,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (e: FocusEvent<HTMLButtonElement>) => void,
  name?: string,
  indeterminate?: boolean,
  disabled?: boolean,
};
const Checkbox = ({
  value,
  onChange,
  onBlur,
  size = 18,
  name,
  indeterminate,
  disabled,
}: CheckboxProps) => (
  <MuiCheckbox
    className={styles.checkbox}
    checked={value}
    name={name}
    onChange={onChange}
    onBlur={onBlur}
    indeterminate={indeterminate}
    disabled={disabled}
    sx={{
      color: 'inherit',
      p: 0,
      '&.Mui-checked': {
        color: 'inherit',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    }}
    icon={(
      <div
        className={styles.icon}
        style={{
          width: size,
          height: size,
        }}
      >
        <Svg name='checkboxEmpty' />
      </div>
          )}
    indeterminateIcon={(
      <div
        className={styles.icon}
        style={{
          width: size,
          height: size,
        }}
      >
        <Svg name='checkboxIndeterminate' />
      </div>
          )}
    checkedIcon={(
      <div
        className={styles.icon}
        style={{
          width: size,
          height: size,
        }}
      >
        <Svg name='checkboxFilled' />
      </div>
    )}
  />
);

export default Checkbox;
